.App {
  text-align: center;
}
body > div.ReactModalPortal > div{
  z-index: 999;
  background-color: rgb(39 39 39 / 75%) !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
:root{
  --azul: #151B4E;
  --verde: #3BAF29;
  --verdeHover: #2e8820;
  --verdeb: #B7DEB2;
  --verdef: #40A859;
  --blanco: #fff;
  --grisb: #F0F1F3;
  --grism: #9597A7;
  --grisf: #666770;
  --fondo: #D9D9D9;
  --SideiconLighttheme: #6A6A6A;
  --BodyContainerLight: #EFF0F2;
  --titlemovil: 22px;
  --regularmovil: 16px;
  --resaltado: bold; 
  --titleLogin: 35px;
  --title: 25px;


  /*  FONDOS */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.ContainerCardRecoMapa{
  /* position: relative;
  display: flex;
  flex-direction: column;
  background: red;
  justify-content: center;
  align-items: center;
  width: 20%;
  margin: auto;
  border-radius: 20px; */
}
.ContainerImagenRecoMapa{
  position: absolute;
  bottom: 35px;
  left: 34%;
}
.ContainerImagenRecoMapaPETICION{
  position: absolute;
  top: -30px;
  left: 38%;
}
.ContainerImagenRecoMapaPETICION img{
  width: 50%;
}
.ContainerImagenRecoMapa img{
  width: 50%;
}
/* .ContainerTextoReco{
  padding-top: 20px;
} */
.ContainerTextoReco p{
  text-align: center;
  font-weight: bold;
}
/* .mapboxgl-popup-content{
  padding: 0px 6px 0px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* .mapboxgl-popup-content{
  padding: 0px 2px 0px;
  height: 36px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 1px solid #fff;
  top: -4px;
  background: #fff;
 box-shadow:none;
} */
.mapboxgl-popup-tip {
  width: 0;
  height: 0;
  border: 8px solid transparent;
border-bottom: none;
  /* border-top-color: #ffb531; */
}
.mapboxgl-popup{
  top: -7px;
  left: -3px;
}
.mapboxgl-popup mapboxgl-popup-anchor-bottom{
  /* max-width: 240px; */
  transform: translate(-50%, -100%) translate(892px, 382px);
  /* position: relative; */
  /* display: flex; */
  /* flex-direction: column; */
  /* background: red; */
  justify-content: center;
  /* align-items: center; */
  /* width: 145%; */
  margin: auto;
  border-radius: 20px;
}
.mapboxgl-popup-anchor-bottom{
z-index: 5;
color: #333333;
font-weight: bold;
filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.29));
}
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right{
  display: flex;
}
/* .mapboxgl-ctrl-top-left{
  background-color: #151B4E;
} */
.mapboxgl-ctrl-top-right{
  position: absolute;
  right: 284px;
  top: 55px;
}
.mapboxgl-ctrl-attrib{
  display: none;
}
.mapboxgl-ctrl-bottom-left{
  display: none;
}
@media only screen and (min-width: 1370px) {
  .mapboxgl-ctrl-top-right{
    right: 293px;
    top: 55px;
  }
}
.mapbox-gl-draw_polygon {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="m15 12.3v-4.6c.6-.3 1-1 1-1.7 0-1.1-.9-2-2-2-.7 0-1.4.4-1.7 1h-4.6c-.3-.6-1-1-1.7-1-1.1 0-2 .9-2 2 0 .7.4 1.4 1 1.7v4.6c-.6.3-1 1-1 1.7 0 1.1.9 2 2 2 .7 0 1.4-.4 1.7-1h4.6c.3.6 1 1 1.7 1 1.1 0 2-.9 2-2 0-.7-.4-1.4-1-1.7zm-8-.3v-4l1-1h4l1 1v4l-1 1h-4z"/>%3C/svg>');
  background-repeat: no-repeat;
  background-position: center;
}
.mapbox-gl-draw_trash {
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20">%3Cpath d="M10,3.4 c-0.8,0-1.5,0.5-1.8,1.2H5l-1,1v1h12v-1l-1-1h-3.2C11.5,3.9,10.8,3.4,10,3.4z M5,8v7c0,1,1,2,2,2h6c1,0,2-1,2-2V8h-2v5.5h-1.5V8h-3 v5.5H7V8H5z"/>%3C/svg>');
  background-repeat: no-repeat;
  background-position: center;
}
#root > section > div > div > div.Container-Body-grid > section > div > div > div > div.mapboxgl-popup.mapboxgl-popup-anchor-bottom{
  max-width: 240px;
  transform: translate(-50%, -100%) translate(892px, 382px);
  /* position: relative; */
  /* display: flex; */
  /* flex-direction: column; */
  /* background: red; */
  justify-content: center;
  /* align-items: center; */
  width: 145%;
  margin: auto;
  border-radius: 20px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
